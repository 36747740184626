export const getActiveAndInactiveList = (reports: any): any => {
  return {
    version: reports.shipment_templates.map((item: any) => item.version).sort().at(-1),
    activeReportsList: reports.shipment_templates.filter((report: any) => report.active),
    inactiveReportsList: reports.shipment_templates.filter((report: any) => !report.active),
  };
};

export const getCreateReportFormDataList = (containerColumns: any): any => {
  const categoryList: any = [...new Set(containerColumns.map((item: any) => item.category))];
  const columnList = categoryList.map((category: any, index: number) => {
    return {
      category,
      active: index == 0 ? true : false,
      id: index + 1,
      sections: [],
    };
  });
  containerColumns.forEach((column: any) => {
    columnList.forEach((item: any) => {
      if (item.category == column.category) {
        if (item.category == column.category && item.sections.length) {
          column['active'] = false;
          const isHaveColumnSection: any = item.sections.some((section: any) => section.section == column.section);
          if (isHaveColumnSection) {
            item.sections.forEach((section: any) => {
              if (section.section == column.section) {
                if (column.values.length == 1) {
                  section.values.push(column.values[0]);
                }
                else {
                  const children: any = [{
                    name: column.name,
                    children: column.values,
                    default: false,
                    isChecked: false,
                  }];
                  const subValues: any = {
                    isMenuValues: true,
                    values: children,
                  };
                  section.values.push(subValues);
                }
              }
            });
          }
          else {
            item.sections.push(newSection(column));
          }
        }
        else {
          item.sections.push(newSection(column));
        }
      }
      return item;
    });
  });
  columnList[0].sections[0].active = true;

  return getColumnsList(columnList);
};

export const getColumnsList = (columnList: any) => {
  const selectedColumns: any = [];
  columnList.forEach((category: any) => {
    const defaultCheckedValues = ['bl_no', 'cntr_no', 'carrier_no'];
    category.sections.forEach((section: any) => {
      section.values = section.values.map((item: any) => {
        if (item.isMenuValues) {
          item.values[0].children = item.values[0].children.map((subValue: any) => {
            if (defaultCheckedValues.includes(subValue.data_key)) {
              subValue['default'] = true;
              subValue.isChecked = true;
              subValue['isHighlight'] = false;
            }
            else {
              subValue.isChecked = false;
              subValue['isHighlight'] = false;
            }
            subValue.isChecked ? selectedColumns.push(subValue) : '';
            return subValue;
          });
        }
        else {
          if (defaultCheckedValues.includes(item.data_key)) {
            item['default'] = true;
            item.isChecked = true;
            item['isHighlight'] = false;
          }
          else {
            item['isHighlight'] = false;
          }
          item.isChecked ? selectedColumns.push(item) : '';
        }
        return item;
      });
      return section;
    });
    return category;
  });
  const sectionList: any = columnList.find((category: any) => category.active).sections;
  const valuesList: any = sectionList.find((section: any) => section.active).values;

  return {
    columnList,
    sectionList,
    valuesList,
    selectedColumns,
  };
};

export const addAndRemoveSelectedValue = (columns: any, selectedValue: any) => {
  if (columns.selectedColumns.some((column: any) => column.data_key == selectedValue.data_key)) {
    const selectedColumns = columns.selectedColumns.filter((column: any) => column.data_key != selectedValue.data_key);
    columns.selectedColumns = selectedColumns;
  }
  else {
    if (selectedValue?.children?.length > 1) {
      selectedValue.children.forEach((item: any) => {
        if (!item.default)
          item.isChecked = selectedValue.isChecked ? true : false;
        if (!columns.selectedColumns.some((column: any) => column.data_key == item.data_key))
          item.isChecked && !item.default && columns.selectedColumns.push(item);
        const isItemExit = columns.selectedColumns.some((column: any) => column.data_key == item.data_key);
        if (!item.isChecked && !item.default && isItemExit) {
          const selectedColumns = columns.selectedColumns.filter((column: any) => column.data_key != item.data_key);
          columns.selectedColumns = selectedColumns;
        }
        return item;
      });
    }
    else {
      columns.selectedColumns.push(selectedValue);
    }
  }
  return {
    columnList: columns.columnList,
    sectionList: columns.sectionList,
    valuesList: columns.valuesList,
    selectedColumns: columns.selectedColumns,
  };
};

export const searchFilterColumnFromColumnList = (columns: any, searchColumn: any) => {
  columns.forEach((category: any) => {
    category.active = category.category == searchColumn.category ? true : false;
    category.sections[0].active = true;
    category.sections.forEach((section: any) => {
      section.active = section.section == searchColumn.section ? true : false;
    });
    return category;
  });

  const updatedColumns = getColumnsList(columns);
  updatedColumns.valuesList = updatedColumns.valuesList.map((item: any) => {
    if (item.isMenuValues) {
      const columnName = searchColumn.columnName.split('...');
      item.values[0].children = item.values[0].children.map((subValue: any) => {
        if (columnName[1] == subValue.name) {
          subValue.isHighlight = true;
        }
        return subValue;
      });
    }
    else {
      const columnName = searchColumn.columnName.split('...');
      if (searchColumn.values.length > 1) {
        const data = searchColumn.values.find((item: any) => item.name == columnName[1]);
        data.name == item.name ? item.isHighlight = true : '';
      }
      else if (item.name == searchColumn.values[0].name) {
        item.isHighlight = true;
      }
    }
    return item;
  });
  return updatedColumns;
};

const newSection = (column: any) => {
  return {
    active: false,
    section: column.section,
    values: column.values,
  };
};

export const getCategoryDropDownList = (containerColumns: any): any => {
  const data = containerColumns.map((column: any) => {
    return column.values && column.values.map((values: any) => {
      if (Array.isArray(values)) {
        return values.length && values.map((subArrayValues: any) => {
          return { ...column, columnName: `${column.category}...${subArrayValues.name}` };
        });
      }
      else {
        return { ...column, columnName: `${column.category}...${values.name}` };
      }
    }).filter((columnItem: any) => columnItem != undefined).flat();
  }).filter((value: any) => value != undefined).flat();
  return data;
};
export const downloadFile = (fileResponse: any, downloadFileName: string): any => {
  const date = new Date();
  const currentDate = date.toISOString().split('T')[0];
  const res = window.URL.createObjectURL(new Blob([fileResponse]));
  const a = document.createElement('a');
  a.href = res;
  a.download = `${downloadFileName}-${currentDate}.xlsx`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(res);
};

export const getReportBuilderTabsData = (report: any, exitData: any, filtersListFromApi: any[]) => {
  const tabsData = JSON.parse(JSON.stringify(report?.tabs)).map((tab: any, index: number) => {
    const existTabData = exitData?.tabsData?.find((existTab: any) => existTab.name == tab.name);
    const tableHeaders = tab.columns.map((column: any) => {
      const existHeader = existTabData?.tableHeaders?.find((item: any) => item.name == column.name);
      const existFilter = tab?.filters?.find((item: any) => item.data_key == column.data_key);
      let description: any;
      if (existFilter?.operator) {
        description = filtersListFromApi.find((filterItem: any) => filterItem.operator == existFilter.operator);
      }

      if (tab.filters.length && existFilter?.data_key == column?.data_key) {
        return {
          label: column.data_key,
          name: column.name,
          filterValues: {
            operator: description.description ? description.description : '',
            searchValue: existFilter.value ? existFilter.value : '',
            filterValue: existHeader?.filterValues?.filterValue ? existHeader?.filterValues?.filterValue : '',
          },
        };
      }
      else {
        return {
          label: column.data_key,
          name: column.name,
          filterValues: {
            operator: existHeader?.filterValues?.operator ? existHeader?.filterValues?.operator : '',
            searchValue: existHeader?.filterValues?.searchValue ? existHeader?.filterValues?.searchValue : '',
            filterValue: existHeader?.filterValues?.filterValue ? existHeader?.filterValues?.filterValue : '',
          },
        };
      }
    });
    tableHeaders.push({ label: '', name: '', filterValues: {} });
    return {
      id: index,
      name: tab.name,
      columns: tab.columns.map((column: any) => column.data_key),
      tableHeaders: tableHeaders,
      tableData: [],
    };
  });
  return tabsData;
};

export const getExistFilters = (existFilterThings: any) => {
  const { activeBuilder, filtersFromApi, filterList } = existFilterThings;
  return activeBuilder.tabs.map((tab: any, index: number) => {
    const filters: any[] = [];
    if (tab.filters.length && filtersFromApi.length) {
      tab.filters.forEach((item: any) => {
        const description = filtersFromApi.find((filterItem: any) => filterItem.operator == item.operator).description || 'is equal to';
        const data_key = tab.columns.find((col: any) => col.data_key == item.data_key).data_key;
        let filterChip = '';
        const column: any = filterList[data_key];
        if (description == 'Custom ') {
          filterChip = getDaysFromDateStamp(item.value, data_key);
        }
        else if (column.data_type == 'datetime') {
          const str = item.value;
          let value = '';
          if (item.value) {
            const cleanedStr = str.replace(/^\[|\]$/g, '');
            const timestamps = cleanedStr.split('..');
            const startDate = new Date(timestamps[0] * 1000);
            const endDate = new Date(timestamps[1] * 1000);
            value = formatDate(startDate, endDate);
          }
          filterChip = `${data_key.replace(/_/g, ' ')}: ${description} ${value}`;
        }
        else {
          filterChip = `${data_key.replace(/_/g, ' ')}: ${description} ${item.value}`;
        }

        filters.push({
          value: filterChip,
          data_key,
        });
      });
    }
    return {
      id: index,
      name: tab.name,
      filters: filters,
    };
  });
};

export const getCurrentSelectedColumn = (reportBuilder: any, tabName: any, filterItem: any) => {
  const tab = reportBuilder.tabsData.find(
    (tab: any) => tab.name == tabName,
  );
  const header = tab.tableHeaders.find(
    (header: any) => header.label == filterItem.data_key,
  );

  return header;
};

function isNumber(value: any) {
  return typeof value === 'number';
}
export const getDaysFromDateStamp = (value: any, data_key: any) => {
  const reportsStore: any = useReportsStore();
  if (isNumber(value)) {
    const totalSeconds = value;
    const totalDays = totalSeconds / (60 * 60 * 24);
    const fromDate = new Date();
    const toDate = new Date();
    toDate.setDate(fromDate.getDate() + totalDays);
    reportsStore.setDateFilterValues(Math.abs(totalDays), totalDays > 0 ? 'Next' : 'Last');
    return `${data_key}: ${totalDays > 0 ? 'Next' : 'Last'} ${Math.abs(totalDays)} Days`;
  }
  else {
    const str = value;
    const cleanedStr = str.replace(/^\[|\]$/g, '');
    const timestamps = cleanedStr.split('..');

    const timestamp1 = Number(timestamps[0]);
    const timestamp2 = Number(timestamps[1]);

    const milliseconds1 = timestamp1 * 1000;
    const milliseconds2 = timestamp2 * 1000;

    const differenceMillis = milliseconds2 - milliseconds1;
    const differenceDays = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
    reportsStore.setDateFilterValues(Math.abs(differenceDays), differenceDays > 0 ? 'Next' : 'Last');
    return `${data_key}: ${differenceDays > 0 ? 'Next' : 'Last'} ${Math.abs(differenceDays)} Days`;
  }
};

const isValidDateFormat = (str: any) => {
  const pattern = /^(\d{1,2} [A-Za-z]{3} '\d{2} \d{2}:\d{2}),(\d{1,2} [A-Za-z]{3} '\d{2} \d{2}:\d{2})$/;
  return pattern.test(str);
};

export const setFilterData = (FilterThings: any) => {
  const { reportBuilder, tabName, headerName, filters, filterList } = FilterThings;
  const header = getCurrentSelectedColumn(reportBuilder, tabName, {
    data_key: headerName?.data_key || headerName?.label,
  });
  const currentFilterValue = filters.find((item: any) => {
    header.filterValues.operator = header.filterValues.operator || 'is equal to';
    if (item.description == header.filterValues.operator) {
      return item;
    }
  });

  const column: any = filterList[headerName.data_key];
  const operator = currentFilterValue.typesense_operator == 'custom' ? ':' : currentFilterValue.typesense_operator;
  header.filterValues.filterValue = `${header.label}${operator}`;
  if (column.data_type !== 'datetime') {
    if (header.filterValues.operator == 'in') {
      header.filterValues.filterValue = `${header.filterValues.filterValue}[${[
        header.filterValues.searchValue,
      ]}]`;
    }
    else {
      header.filterValues.filterValue
        = header.filterValues.filterValue
        + (header.filterValues.searchValue || 'undefined');
    }
  }
  else {
    if (isValidDateFormat(header.filterValues.searchValue)) {
      let dateValue = '';
      const date = header.filterValues.searchValue?.split(',');
      if (date?.length == 2) {
        dateValue = `[${Math.floor((new Date(date[0])).getTime() / 1000)}..${Math.floor((new Date(date[1])).getTime() / 1000)}]`;
      }
      const filterValue = dateValue ? dateValue : header.filterValues.dateValue;
      header.filterValues.filterValue = `${header.filterValues.filterValue} ${filterValue ? filterValue : 'undefined'}`;
    }
    else {
      const filterValue = header.filterValues.searchValue;
      header.filterValues.filterValue = `${header.filterValues.filterValue} ${filterValue ? filterValue : 'undefined'}`;
    }
  }
};

const getDateValueByDate = (date: any) => {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  const hour = date.getHours();
  const minute = date.getMinutes();
  return {
    day,
    monthIndex,
    year,
    hour,
    minute,
  };
};

const formatDate = (startDate: any, lastDate: any) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date1 = getDateValueByDate(startDate);
  const date2 = getDateValueByDate(lastDate);
  if (date1.day == date2.day) {
    const today = new Date();
    const hour = today.getHours();
    const minute = today.getMinutes();
    return `${date1.day - 1} ${months[date1.monthIndex]} '${
      date1.year
    } ${date2.hour || hour}:${date2.minute || minute},${date2.day} ${
      months[date2.monthIndex]
    } '${date2.year} ${date2.hour || hour}:${(date2.minute || minute) - 1}`;
  }
  return `${date1.day - 1} ${months[date1.monthIndex]} '${date1.year} ${
    date2.hour
  }:${date2.minute},${date2.day} ${months[date2.monthIndex]} '${date2.year} ${
    date2.hour
  }:${date2.minute - 1}`;
};

export const getFilterValue = (reportBuilder: any, tabName: any) => {
  const data = (reportBuilder.tabsData || [])
    .map((item: any) => {
      return (
        item.name == tabName
        && item.tableHeaders
          .map((header: any) => {
            return header.filterValues.filterValue || '';
          })
          .filter((item: any) => item)
      );
    })
    .filter((item: any) => item != '')
    .flat();
  let filterValues = '';
  data.forEach((item: any) => {
    filterValues = `${filterValues}${item} && `;
  });
  return filterValues;
};

export const getTimeFromTimeStamp = (timestamp: any) => {
  const date = new Date(timestamp * 1000);

  const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
  return date.toLocaleDateString(undefined, options);
};

export const UpdateHeaderColumns = (singleReportData: any) => {
  singleReportData.tabsData.forEach((tab: any) => {
    tab.tableData = tab.tableData.map((item: any) => {
      const headerExitKeys = tab.tableHeaders.map((header: any) => header.label);
      tab.columns = headerExitKeys;
      const rowValues: any = [];
      headerExitKeys.forEach((key: string) => {
        const row = item.find((rowValue: any) => rowValue.key == key);
        rowValues.push(row);
      });
      rowValues.push({ key: '', value: '' });
      return rowValues;
    });
    return tab;
  });

  return {
    tabsData: singleReportData.tabsData,
    found: singleReportData.found,
  };
};

export const getTableColumnsFormReportAndTrackingData = (tabsData: any, trackingData: any, state: any) => {
  if (state.pageNumber == 1) {
    tabsData = JSON.parse(JSON.stringify(tabsData));
  }
  tabsData.forEach((tab: any) => {
    tab.tableData = (trackingData?.hits || []).map((item: any) => {
      const rowValues: any = [];
      const apiDataKeys = Object.keys(item);
      tab.columns.forEach((headerKey: any) => {
        apiDataKeys.forEach((apiKey) => {
          if (apiKey == headerKey) {
            rowValues.push({ key: headerKey, value: (item[headerKey] || '') });
          }
        });
        if (!apiDataKeys.includes(headerKey))
          rowValues.push({ key: headerKey, value: '' });
      });
      rowValues.push({ key: '', value: ('') });
      return rowValues;
    });
    const data = tab.tableHeaders.filter((header: any) => {
      if (header.filterValues.operator || header.filterValues.searchValue) {
        return {
          chipValue: header.filterValues.operator || header.filterValues.searchValue ? `${header.name}: ${header.filterValues.operator} ${header.filterValues.searchValue}` : '',
          headerName: header.name,
        };
      }
    });
    tab.filterChips = data;
    return tab;
  });
  if (state.pageNumber > 1) {
    const currentTabData = state.reportBuilder.tabsData.find((item: any) => item.name == state.currentTab);
    const tab = tabsData.find((item: any) => item.name == state.currentTab);
    tab.tableData = [...currentTabData.tableData, ...tab.tableData];
  }

  return {
    tabsData: tabsData,
    found: trackingData.found || 0,
  };
};

export const createNewReport = (selectedColumns: any, version: number, schedule: any, userStore: any) => {
  const columns = selectedColumns.map((item: any) => {
    delete item['default'];
    delete item['isChecked'];
    delete item['isHighlight'];
    return item;
  });
  const newReportData = [{
    schedule: schedule.value.schedule ? schedule.value.schedule : null,
    recipients: [userStore.email],
    report_type: 'Container',
    name: schedule.value.name,
    tabs: [{
      name: 'Containers',
      columns,
      filters: [],
    },
    ],
    version: version + 1,
    date_format: 'YYYY-MM-DD',
    is_editable: true,
    org_id: userStore.activeOrgId,
    created_by: [userStore.email],
  }];

  return newReportData;
};

const getExitColumnsInReport = (currentReport: any, currentTab: string) => {
  const tab = currentReport?.tabs.find((item: any) => item.name == currentTab);
  return tab?.columns.map((column: any) => {
    column.isChecked = true;
    return column;
  });
};

export const resetReportColumns = (columns: any, currentReport: any, currentTab: string) => {
  const selectedColumns = currentReport && currentTab ? getExitColumnsInReport(currentReport, currentTab) : [];
  const selectedColumnNames = currentReport ? selectedColumns?.map((column: any) => column.name) : ['bl_no', 'cntr_no', 'carrier_no'];
  columns.valuesList = columns.valuesList.map((item: any) => {
    item.isChecked = selectedColumnNames?.includes(item.name) ? true : false;
    return item;
  });
  columns.selectedColumns = selectedColumns;
  columns.columnList.forEach((category: any) => {
    const checkedValues = selectedColumns.map((item: any) => item.data_key);
    const defaultCheckedValues = ['bl_no', 'cntr_no', 'carrier_no'];
    category.sections.forEach((section: any) => {
      section.values = section.values.map((item: any) => {
        if (item.isMenuValues) {
          item.values[0].children = item.values[0].children.map((subValue: any) => {
            if (checkedValues.includes(subValue.data_key)) {
              item['default'] = defaultCheckedValues.includes(item.data_key) ? true : false;
              subValue.isChecked = true;
              subValue['isHighlight'] = false;
            }
            else {
              subValue.isChecked = false;
              subValue['isHighlight'] = false;
            }
            return subValue;
          });
        }
        else {
          if (checkedValues.includes(item.data_key)) {
            item['default'] = defaultCheckedValues.includes(item.data_key) ? true : false;
            item.isChecked = true;
            item['isHighlight'] = false;
          }
          else {
            item['isHighlight'] = false;
          }
        }
        return item;
      });
      return section;
    });
    return category;
  });
  const sectionList: any = columns.columnList.find((category: any) => category.active)?.sections;
  const valuesList: any = sectionList?.find((section: any) => section.active).values;

  return {
    columnList: columns.columnList,
    sectionList,
    valuesList,
    selectedColumns,
  };
};

export const changeSelection = (columnType: any, selectedColumnName: string, columns: any) => {
  if (columnType == 'category') {
    columns.columnList.forEach((category: any, index: number) => {
      category.active = category.category == selectedColumnName ? true : false;
      if (!selectedColumnName && index == 0) {
        category.active = true;
      }
      category.sections.forEach((section: any, index: number) => {
        section.active = index == 0 ? true : false;
      });
      return category;
    });
  }
  else {
    columns.columnList.forEach((category: any) => {
      if (category.active) {
        category.sections.forEach((section: any) => {
          section.active = section.section == selectedColumnName ? true : false;
          return section;
        });
      }
      return category;
    });
  }
};

export const getFiltersList = (filtersList: any, isFourOptionList: boolean) => {
  const descriptionListItems = ['in', 'not in', 'is not equal to', 'is equal to'];
  const list = filtersList.filter((item: any) => {
    if (isFourOptionList && descriptionListItems.includes(item.description)) {
      return item;
    }
    else if (!isFourOptionList) {
      return item;
    }
  });
  return list;
};

export const getCurrentDefault = (report: any) => {
  return report ? report.tabs[0].name : '';
};
