import {
  getActiveAndInactiveList, getCreateReportFormDataList, getCategoryDropDownList,
  getColumnsList, searchFilterColumnFromColumnList, addAndRemoveSelectedValue, downloadFile,
  getTableColumnsFormReportAndTrackingData, getReportBuilderTabsData,
  resetReportColumns, UpdateHeaderColumns, getCurrentDefault, getExistFilters, getFilterValue, setFilterData,
} from '~/utils/reports/reports.util';

export const useReportsStore = defineStore('reportsStore', {
  state: () => ({
    reports: {},
    activeReportsList: [],
    inactiveReportsList: [],
    version: 0,
    categoryDropDownList: [],
    activeBuilder: [],
    reportBuilder: {},
    reportBuilderFilterList: [],
    defaultDates: {
      dates: [],
      operator: '',
      data_key: '',
      filterTypeList: ['Next', 'Last'],
      filterType: '',
      days: '',
    },
    ladingTracking: {
      records: [],
      page: 1,
      tab: '',
      pages: 0,
    },
    containerColumnList: [],
    editReportTableFilter: [],
    isReportEdit: false,
    querySearch: '',
    filters: {
      filterList: [],
      apiResponseList: [],
    },
    createColumnList: {
      columnList: [],
      sectionList: [],
      valuesList: [],
      selectedColumns: [],
    },
    orgUserList: [],
    selectedReportName: '',
  }),
  actions: {
    async fetchReports() {
      const userStore = useUserStore();
      const { $cognito, $ofetch }: any = useNuxtApp();
      const cognitoSession = await $cognito.getSession();
      const orgIds = [
        '356f1867-0426-57b9-86d6-5b1014b2e19d',
        userStore.activeOrgId,
      ];
      const url = `/api/reports/get-shipment-templates?org_id=${orgIds[0]}&org_id=${orgIds[1]}`;
      const results: any = await $ofetch(url, { headers: { Authorization: `Bearer ${cognitoSession?.jwtToken}` } });
      const activeAndInactiveList = getActiveAndInactiveList(results);
      this.activeReportsList = activeAndInactiveList.activeReportsList;
      this.inactiveReportsList = activeAndInactiveList.inactiveReportsList;
      this.version = activeAndInactiveList.version;
    },
    async fetchContainerColumns() {
      const userStore = useUserStore();
      const { $cognito, $ofetch }: any = useNuxtApp();
      const cognitoSession = await $cognito.getSession();
      const url = `/api/reports/get-container-columns-v2?org_id=${userStore.activeOrgId}`;
      const results: any = await $ofetch(url, { headers: { Authorization: `Bearer ${cognitoSession?.jwtToken}` } });
      const shipmentTemplatesColumns: any = JSON.parse(JSON.stringify(results.shipment_templates_columns));
      this.createColumnList = getCreateReportFormDataList(shipmentTemplatesColumns);
      this.categoryDropDownList = getCategoryDropDownList(results.shipment_templates_columns);
    },
    async resetColumns(currentReport: any, currentTab: string) {
      this.createColumnList = resetReportColumns(this.createColumnList, currentReport, currentTab);
    },
    async updateColumns() {
      this.createColumnList = getColumnsList(this.createColumnList.columnList);
    },
    async addSelectedValue(column: any) {
      this.createColumnList = addAndRemoveSelectedValue(this.createColumnList, column);
    },

    async searchFilterColumn(searchColumnValue: any) {
      this.createColumnList = searchFilterColumnFromColumnList(this.createColumnList.columnList, searchColumnValue);
    },
    async fetchOrgUserList() {
      const userStore = useUserStore();
      const { $cognito, $ofetch }: any = useNuxtApp();
      const cognitoSession = await $cognito.getSession();
      const url = `/api/reports/get-org-wise-users?org_id=${userStore.activeOrgId}`;
      const { org_users }: any = await $ofetch(url, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${cognitoSession?.jwtToken}`,
        },
      });
      this.orgUserList = org_users?.map((user: any) => user.portcast_user);
    },
    async updateReportName(reportId: string, name: string) {
      const { $cognito, $ofetch }: any = useNuxtApp();
      const cognitoSession = await $cognito.getSession();
      const url = `/api/reports/update-report-name`;
      $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({
          id: reportId,
          name: name,
        }),
      }).then(() => {
        this.fetchReports();
      });
    },
    async updateActiveStatus(reportId: string, isActive: string) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/update-active-status`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({
          id: reportId,
          active: isActive,
        }),
      }).then(() => {
        this.fetchReports();
      });
    },
    async createReport(newReport: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/create-report`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({ objects: newReport }),
      }).then(() => {
        this.fetchReports();
      });
    },
    async deleteReportByReportId(reportId: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports?id=${reportId}`;
      await $ofetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
      }).then(() => {
        this.fetchReports();
      });
    },
    async updateDateAndTimeFormatOfReport(reportId: any, dateFormate: string) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/save-headers`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({
          id: reportId,
          objects: { date_format: dateFormate },
        }),
      });
    },
    async updateScheduleReport(reportId: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      const url = `${runtimeConfig.public.BUI_URL}shipments/template/schedule?template_id=${reportId}`;
      await $ofetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
      });
    },
    async sendEmail(report: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      const url = `${runtimeConfig.public.BUI_URL}shipments/template/email`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({ ...report }),
      });
    },
    async downloadReport(report: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      const url = `${runtimeConfig.public.BUI_URL}shipments/template/download`;
      const results: any = await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({ ...report }),
      });
      downloadFile(results, report.name);
    },
    async getContainerColumnsData() {
      const userStore = useUserStore();
      const { $cognito, $ofetch }: any = useNuxtApp();
      const cognitoSession = await $cognito.getSession();
      const url = `/api/reports/get-container-columns-v2-flatten?org_id=${userStore.activeOrgId}`;
      const results: any = await $ofetch(url, {
        headers: { Authorization: `Bearer ${cognitoSession?.jwtToken}` },
      });
      this.containerColumnList = results;
      this.fetchFilters();
    },

    async getContainerReport(id: number) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      // const orgIds = ['356f1867-0426-57b9-86d6-5b1014b2e19d', userStore.activeOrgId];
      const url = `/api/reports/get_container_report?org_id=${userStore.activeOrgId}&id=${id}`;
      const results: any = await $ofetch(url, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
      });
      this.activeBuilder = results;
      this.ladingTracking.tab = getCurrentDefault(this.activeBuilder);
      this.getContainerColumnsData();
    },
    async getBillOfLadingTracking(queryValue: string, currentTab: string, pageNumber: number, filters: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const page = pageNumber || 1;
      const q = queryValue || '*';
      const filterBy = `${filters || ''}deleted:=false && system_deleted:=false`;
      const runtimeConfig = useRuntimeConfig();
      const url = `${runtimeConfig.public.BUI_URL}bill-of-lading-tracking/?org_id=${userStore.activeOrgId}&q=${encodeURIComponent(q)}&page=${page}&per_page=50&query_by=${encodeURIComponent('cntr_no, bl_no, carrier_no')}&filter_by=${encodeURIComponent(filterBy)}`;

      const currentStateOfReport = {
        reportBuilder: this.reportBuilder,
        currentTab: currentTab,
        pageNumber,
      };
      const utilsStore = useUtilsStore();

      try {
        utilsStore.setGlobalLoader(true);
        const results: any = await $ofetch(url, {
          headers: {
            method: 'GET',
            Authorization: `Bearer ${userStore.cognitoSession?.jwtToken}`
            ,
          },
        });

        if (results) {
          this.ladingTracking.pages = Math.ceil(results.found / 50);
        }

        const tabsData = getReportBuilderTabsData(this.activeBuilder, this.reportBuilder, this.filters.apiResponseList);
        this.reportBuilder = getTableColumnsFormReportAndTrackingData(tabsData, results || [], currentStateOfReport);
        this.ladingTracking.page = pageNumber;
      }
      catch {
        const currentStateOfReport = {
          reportBuilder: this.reportBuilder,
          currentTab: currentTab,
          pageNumber,
        };
        const tabsData = getReportBuilderTabsData(this.activeBuilder, this.reportBuilder, this.filters.apiResponseList);
        this.reportBuilder = getTableColumnsFormReportAndTrackingData(tabsData, [], currentStateOfReport);
      }
      finally {
        utilsStore.setGlobalLoader(false);
      }
    },
    async setTableHeaders() {
      this.reportBuilder = UpdateHeaderColumns(this.reportBuilder);
    },
    async saveEmail(report: any, updatedData: string) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/save-headers`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: report.id, objects: updatedData }),
      });
    },
    async updateReport(report: any, updatedData: string) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/save-headers`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: report.id, objects: updatedData }),
      });
      this.activeBuilder.tabs.forEach((item: any) => {
        item.filters.forEach((filter: any) => {
          const setFilterValuesList = {
            reportBuilder: this.reportBuilder,
            tabName: item.name,
            headerName: filter,
            filters: this.filters.apiResponseList,
            filterList: this.containerColumnList,
          };
          setFilterData(setFilterValuesList);
        });
      });

      const data = getFilterValue(this.reportBuilder, this.ladingTracking.tab);
      this.getBillOfLadingTracking(this.querySearch, this.ladingTracking.tab, this.ladingTracking.page, data);
    },
    async fetchFilters() {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/get-filters`;
      const results: any = await $ofetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
          'Content-Type': 'application/json',
        },
      });
      this.filters.apiResponseList = results.shipment_template_filters;
      this.setEditReportData(this.activeBuilder, this.ladingTracking.tab, 1);
    },

    async saveFilters(report: any, tabName: any, reportBuilderUpdatedData?: any) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/save-headers`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({
          id: report.id,
          objects: { name: report.name, tabs: report.tabs },
        }),
      });

      this.setEditReportData(report, tabName, reportBuilderUpdatedData);
    },

    async setEditReportData(report: any, tabName: any, reportBuilderUpdatedData?: any) {
      const currentStateOfReport = {
        reportBuilder: this.reportBuilder,
        currentTab: tabName ? tabName : this.activeBuilder.tabs[0].name,
        pageNumber: this.ladingTracking.page ? this.ladingTracking.page : 1,
      };
      const tabsData = getReportBuilderTabsData(report, reportBuilderUpdatedData, this.filters.apiResponseList);
      reportBuilderUpdatedData = getTableColumnsFormReportAndTrackingData(tabsData, [], currentStateOfReport);
      this.activeBuilder.tabs.forEach((item: any) => {
        item.filters.forEach((filter: any) => {
          const FilterThings = {
            reportBuilder: reportBuilderUpdatedData,
            tabName: item.name,
            headerName: filter,
            filters: this.filters.apiResponseList,
            filterList: this.containerColumnList,
          };
          setFilterData(FilterThings);
        });
      });
      const data = getFilterValue(reportBuilderUpdatedData, tabName);
      const existFilterThings = {
        activeBuilder: this.activeBuilder,
        filtersFromApi: this.filters.apiResponseList,
        filterList: this.containerColumnList,
      };
      this.reportBuilderFilterList = getExistFilters(existFilterThings);
      this.getBillOfLadingTracking('', tabName, 1, data);
    },

    async updateFilters(reportId: any, dateFormate: string) {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/reports/save-headers`;
      await $ofetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.cognitoSession?.jwtToken}`,
        },
        body: JSON.stringify({
          id: reportId,
          objects: { date_format: dateFormate },
        }),
      });
    },

    setFiltersList(headerElement: any) {
      const column: any = this.containerColumnList[headerElement.label];
      const filterList = this.filters.apiResponseList.filter((item: any) => {
        if (item.input_data_types.includes(column?.data_type)) {
          return item;
        }
      });
      return {
        operatorList: filterList,
        column,
      };
    },

    getColumnFromContainerList(headerElement: any) {
      return this.containerColumnList[headerElement.data_key];
    },

    setIsReportEdit() {
      this.isReportEdit = !this.isReportEdit;
    },
    setQueryValue(query: string) {
      this.querySearch = query;
      this.getBillOfLadingTracking(query || '', '', 1, '');
    },

    setDateFilterValues(days: any, filterType: any) {
      this.defaultDates.days = days;
      this.defaultDates.filterType = filterType;
    },
  },
  getters: {
    getReportName: (state: any): string | null => {
      return state.selectedReportName;
    },

    getTotalPages: (state: any): string | null => {
      return state.ladingTracking.pages;
    },
  },
});
